import './App.css';
import logo from './assets/small-logo.png';
import logolong from './assets/logo.png';

import {ReactComponent as ImageClock} from './assets/Clock.svg';
import {ReactComponent as ImageInsight} from './assets/Growth.svg';
import {ReactComponent as ImageSecurity} from './assets/Fist.svg';

import heroImage from './assets/style3.jpg';
import highlightBlockImage1 from './assets/style4.jpg';
import productImage1 from './assets/devices.jpg';
import productImage2 from './assets/phoneroll.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';

import React from 'react';
import { AppBar, Button, Container, CssBaseline, IconButton, Toolbar, Typography, Grid, Stack, Box } from '@mui/material';

function App() {
  return (

<>

      {/* Navigation */}
      <Box>
        <Box sx={{ mb: 10 }}>

        <CssBaseline />

        <AppBar 
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: 'white',
          }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo} alt="FasterFood logo" style={{ height: '2rem', marginRight: '1rem' }} />
            </Typography>
            <Link href="https://ke1.faster.company">
              <Button id="trial-button" variant="contained">
                Try 14-days for 100KSH
                </Button>
            </Link>
          </Toolbar>
        </AppBar>

        {/* Hero */}
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            mt: '2rem',
            backgroundImage: `url(${heroImage})`,
            backgroundSize: 'cover',
            minHeight: '600px', // Adjust the height as needed
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '2rem',
          }}
        >
          <Grid container>
            <Grid xs={12}>
              <img src={logolong} alt="FasterFood logo" style={{ maxHeight: '4rem', marginBottom: '2rem', maxWidth:"100%"}}  />
              <Typography variant="h4" gutterBottom >
                The best restaurant management system.
              </Typography>
              <Typography variant="body1" >
                Ultra affordable order taking and casual bookkeeping App.
              </Typography>
            </Grid>
          </Grid>
        </Container>


<Container maxWidth="lg" sx={{ mt: 10, mb: 16 }}>
<Box sx={{ flexGrow: 1 }}>

<Grid container maxWidth="lg" >

  <Grid item md={6}>
    <img src={productImage2} alt="mockup of app homescreen" style={{ width: '100%' }} />
  </Grid>

  <Grid xs={12}  md={4} display="flex" justifyContent="center" alignItems="center">
    <Stack  justifyContent="center" alignItems="center">
      <Typography variant="h5" gutterBottom>
        Casual Book Keeping
      </Typography>
      <Typography variant="body1" gutterBottom align='center'>
        No more calculations and notebooks, FasterFood does it for you.
      </Typography>
    </Stack>

  </Grid>

  <Grid xs={12}  md={4} display="flex" justifyContent="center" alignItems="center">
    <Stack  justifyContent="center" alignItems="center">

      <ImageSecurity width="100%" height="100%" />

      <Typography variant="h5" gutterBottom>
        Security & Privacy
      </Typography>
      <Typography variant="body1" gutterBottom align='center'>
        Your data is safe with us. No one can access your data without your permission.
      </Typography>
    </Stack>
  </Grid>

  

  <Grid xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
    <Stack  justifyContent="center" alignItems="center">
  
    <ImageClock width="100%" height="100%" />

      <Typography variant="h5" gutterBottom>
        Wait-Time Measurment
      </Typography>
      <Typography variant="body1" gutterBottom align='center'>
        Do you know how long your customers is waiting? FasterFood can tell you that.
      </Typography>
    </Stack>
  </Grid>

  <Grid xs={12}  md={4} display="flex" justifyContent="center" alignItems="center">
    <Stack  justifyContent="center" alignItems="center">

      <ImageInsight width="100%" height="100%" />

      <Typography variant="h5" gutterBottom>
        Customer Insight
      </Typography>
      <Typography variant="body1" gutterBottom align='center'>
        How much you make revenue this week? FasterFood calculates that for you.
      </Typography>
    </Stack>
  </Grid>



</Grid>
</Box>

</Container>

      <Container id="product" sx={{ mt: 10, mb: 16 }} maxWidth="lg">
          <Grid container justifyContent="space-between">
            <Grid item md={6}>
              <img src={productImage1} alt="mockup of devices with product" style={{ width: '100%' }} />
            </Grid>
            <Grid item md={6}>
              <Typography variant="h4" gutterBottom>
                Mobile application for restaurant management
              </Typography>
              <Typography variant="body1">
                Introducing FasterFood, a mobile application for restaurant management. Our revolutionary system brings
                efficiency to the African restaurant industry, offering a seamless point-of-sale ordering experience. With
                lightning-fast orders at their fingertips, restaurants can streamline operations, enhance the dining
                experience, and delight customers with convenient and efficient service.
              </Typography>
            </Grid>
          </Grid>

        </Container>

      {/* Content blocks */}
      <Container id="mission" sx={{ backgroundColor: '#F8F9FA', mt: 10, mb: 16 }} maxWidth="lg">
          <Grid container justifyContent="space-between">
            <Grid item md={6} sx={{
              //padding start and end 4, 
              p: 4, 
            }}>
              <img src={highlightBlockImage1} alt="photo of Kenya" style={{ width: '100%' }} />
            </Grid>
            <Grid item md={6} sx={{pt:4}}>
              <Typography variant="h4" gutterBottom>
                Our mission
              </Typography>
              <Typography variant="body1">
                At Faster Food Company, our business is revolutionizing the African restaurant industry by providing an innovative
                and seamless restaurant ordering system. Our mission is to empower African restaurants with
                lightning-fast orders at their fingertips, combining efficiency and flavor to enhance the dining experience
                for both customers and businesses alike. We are dedicated to enabling restaurants across Africa to thrive,
                streamline operations, and delight customers with convenient and efficient service, paving the way for a new
                era of culinary excellence.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      

</Box>
</Box>
      {/* Footer */}
      <footer >
        <Container maxWidth={false} disableGutters sx={{ mt: 5, mb: 2 }} sx={{
          backgroundColor: '#F8F9FA',
        }}>
          <Box sx={{p:2}}>
            <Grid container justifyContent={'space-between'}>
              <Grid container md={6}>
                <Stack sx={{mb:5}}>
                  <Grid item xs={12}>
                    <Typography variant='overline'>
                      ©️ 2023 Faster Food Company. 
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption'>
                      Faster Food Company is a US registered company, and it's subsidiary company, Faster Friends Company is a registered company in Kenya. 
                    </Typography>
                  </Grid>
                </Stack>
              </Grid>
              <Grid container md={6} justifyContent={"end"} align="end">
                <Stack sx={{mb:5}}>
                <Grid item xs={12} >
                    <Link href="https://www.notion.so/faster-food-company/Job-Board-40200eefe46c4cf3b973ab7c15fd88fd?pvs=4" underline="none">
                      <Typography variant='overline'>
                        Jobs
                      </Typography>
                      </Link>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>
                    Kenya Office: 
                    Iten Eldoret Rd, Eldoret, Kenya 
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption'>
                    US Office : 548 Market St, San Francisco California 94104, USA 
                    </Typography>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Box>

        </Container>
      </footer>

</>
    
);
}

export default App;
